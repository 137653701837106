// Class Notification
export class NotificationModel {
    constructor(id, social, target, content, status, codeError, reasonError, dateTime, quantitySend, option) {
        this.id = id;
        this.social = this.nameTitle(social, option);
        this.target = target;
        this.content = this.checkContent(social, content);
        this.contentDetail = content;
        this.status = status;
        this.statusLabel = this.statusToString(status)
        this.codeError = codeError;
        this.reasonError = reasonError;
        this.dateTime = dateTime;
        this.quantitySend = quantitySend;
    }
    statusToString(status) {
        switch (status) {
            case 1:
                return 'Thành công';
            case 2:
                return 'Thất bại';
            case 3:
                return 'Đã gửi lại';
        }
    }
    nameTitle(target, option) {
        return `${target} ${option}`;
    }
    checkContent(provider, content) {
        if (provider.includes('zalo')) {
            return 'Xem tại chi tiết ticket';
        } else {
            return content;
        }
    }
}

export class ErrorModel {
    constructor(index, phone, target, errors) {
        this.index = index;
        this.phone = phone;
        this.target = target;
        this.errors = errors.join(", ");
    }
}
// Option send
const chanelOption = [
    {
        label: 'Zalo',
        value: 'zalo'
    },
    {
        label: 'Telegram',
        value: 'telegram'
    },
    {
        label: 'SMS',
        value: 'sms'
    },
]
// Status send notification
const statusSend= [
    {
        label: 'Thành công',
        value: '1'
    },
    {
        label: 'Thất bại',
        value: '2'
    },
    {
        label: 'Gửi lại',
        value: '3'
    },
]
// Type send notification
const typeSendOption = [
    {
        label: 'Gửi theo lớp',
        value: 1,
    },
    {
        label: 'Gửi theo danh sách',
        value: 2,
    }
]
// Option target send notification
const optionTarget = [
    // {
    //     label: 'Tất cả',
    //     value: -1,
    // },
    {
        label: 'App game',
        value: 0,
    },
    {
        label: 'App phụ huynh (Ecoach)',
        value: 1,
    },
    {
        label: 'App phụ huynh(Ielts Mentor)',
        value: 2,
    },
    {
        label: 'App trẻ em',
        value: 3,
    },
]
export {
    chanelOption,
    statusSend,
    typeSendOption,
    optionTarget,
}
